<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
        返回
      </fb-button>
      <!-- <fb-button slot="right" icon="menu" size="small" type="primary">
      </fb-button> -->
    </fb-header>
    <!-- 默认插槽 -->
    <div>
      <van-collapse v-model="activeNames"
                    accordion>
        <van-collapse-item v-for="(item,index) in list"
                           :key="index"
                           :name="index"
                           :title="item.summary">
          <div class="shadow"
               id="cursor">
            <p style='border:none'>{{item.case_name}}</p>
            <p>裁判日期：{{item.pub_date}}</p>
            <p>案由：{{item.summary}}</p>
            <p>法院：{{item.court_name}}</p>
            <span>{{item.court_proceeding}}</span>
          </div>
        </van-collapse-item>
      </van-collapse>
    </div>
  </fb-page>
</template>

<script>
import { caseBase, pydataBase } from '~api-config'
import Qs from 'qs'
export default {
  name: 'history-case',
  data () {
    return {
      title: '历史案件',
      activeNames: 0,
      list: []
    }
  },
  created () {
    this.$axios
      .get(
        caseBase +
        '/lts/litigant/queryLitigantListByCaseId?caseId=' +
        this.$route.params.caseId
      )
      .then(response => {
        const name = response.data.data.filter(
          x => x.category !== 'NATURAL_PERSON'
        )
        if (name.length === 0) {
          this.$toast('无非自然人')
        } else {
          const http = this.$axios.create()
          name.forEach(x => {
            http
              .post(
                pydataBase + '/api/company',
                Qs.stringify({
                  name: x.name,
                  page: 1
                })
              )
              .then(response => {
                if (response.data.result.length === 0) {
                  this.$toast('无数据')
                } else {
                  this.list = [...this.list, ...response.data.result]
                }
              })
          })
        }
      })
  }
}
</script>

<style lang="stylus" scoped>
.shadow
  padding 5px 15px
  padding-top 15px
  border-radius 5px
  box-shadow 0px 0px 5px rgba(86, 155, 250, 0.49) !important
  position relative
  & > span
    position absolute
    right 20px
    top 0
    padding 0px 20px
    background #569AF9
    color #fff
    border-radius 5px
    font-size 12px
</style>
