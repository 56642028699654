var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "van-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "van-collapse-item",
                { key: index, attrs: { name: index, title: item.summary } },
                [
                  _c(
                    "div",
                    { staticClass: "shadow", attrs: { id: "cursor" } },
                    [
                      _c("p", { staticStyle: { border: "none" } }, [
                        _vm._v(_vm._s(item.case_name)),
                      ]),
                      _c("p", [_vm._v("裁判日期：" + _vm._s(item.pub_date))]),
                      _c("p", [_vm._v("案由：" + _vm._s(item.summary))]),
                      _c("p", [_vm._v("法院：" + _vm._s(item.court_name))]),
                      _c("span", [_vm._v(_vm._s(item.court_proceeding))]),
                    ]
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }